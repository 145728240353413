<template>
  <div class="goodsSearchs">
    <div class="main-card" style="display: flex">
      <div class="left">
        <div
          class="l-all"
          @click="toALLCategory"
          v-if="categoryLists.length != 0"
        >
          <svg
            viewBox="0 0 12 10"
            class="
              shopee-svg-icon
              shopee-category-list__header-icon
              icon-all-cate
            "
            style="width: 12px; height: 16px; margin-right: 10px"
          >
            <g fill-rule="evenodd" stroke="none" stroke-width="1">
              <g transform="translate(-373 -208)">
                <g transform="translate(155 191)">
                  <g transform="translate(218 17)">
                    <path d="m0 2h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"></path>
                    <path d="m0 6h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"></path>
                    <path
                      d="m0 10h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span>全部分类</span>
        </div>
        <div class="l-category" v-if="categoryLists.length != 0">
          <div
            class="item"
            :class="index == 999 ? 'item-active' : ''"
            @click="handleClick(categoryList, 999)"
          >
            <div style="width: 20px; height: 10px">
              <i class="el-icon-caret-right" v-if="index == 999"></i>
            </div>
            <div>
              <span>{{ categoryList.name }}</span>
            </div>
          </div>
          <div
            class="item"
            v-for="(item, i) in categoryLists"
            :key="item.category_id"
            :class="index == i ? 'item-active' : ''"
            @click="handleClick(item, i)"
          >
            <div style="width: 20px; height: 10px">
              <i class="el-icon-caret-right" v-if="index == i"></i>
            </div>
            <div>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="item" v-if="show">
            <div style="width: 20px; height: 10px"></div>
            <div
              @click="
                categoryLists = categoryList.list;
                show = false;
              "
            >
              <span
                >更多
                <i class="el-icon-arrow-down"></i>
              </span>
            </div>
          </div>
        </div>

        <div style="margin: 10px 0">
          <svg
            enable-background="new 0 0 15 15"
            viewBox="0 0 15 15"
            x="0"
            y="0"
            class="shopee-svg-icon"
            style="width: 12px; height: 12px; margin-right: 10px"
          >
            <g>
              <polyline
                fill="none"
                points="5.5 13.2 5.5 5.8 1.5 1.2 13.5 1.2 9.5 5.8 9.5 10.2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke="#111"
              ></polyline>
            </g>
          </svg>
          <span>条件筛选</span>
        </div>
        <div class="l-price">
          <div style="margin-bottom: 10px">价格范围</div>
          <div>
            <el-input
              v-model="minNum"
              placeholder="RM最小值"
              size="mini"
              style="width: 70px"
            ></el-input>
            <span style="margin: 0 4px; color: rgba(0, 0, 0, 0.8)">——</span>
            <el-input
              size="mini"
              v-model="maxNum"
              placeholder="RM最大值"
              style="width: 70px"
            ></el-input>
          </div>
          <el-button
            size="mini"
            type="primary"
            style="min-width: 190px; margin-top: 20px"
            @click="getGoodsList"
            >套用</el-button
          >
        </div>
        <div class="l-evaluation">
          <div style="margin-bottom: 10px">评价</div>
          <div
            v-for="(item, i) in rate"
            :key="i"
            class="item"
            style="display: flex"
          >
            <el-rate
              disabled-void-icon-class="el-icon-star-off"
              disabled-void-color="#ffce3d"
              disabled
              v-model="item.num"
            ></el-rate>
            <span v-if="i != 0">& 以上</span>
          </div>
        </div>
        <div class="l-condition">
          <div style="margin-bottom: 10px">商品状况</div>
          <el-checkbox-group v-model="checkList" @change="getGoodsList">
            <div style="margin-bottom: 10px">
              <el-checkbox label="0">新</el-checkbox>
            </div>
            <div>
              <el-checkbox label="1">二手</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div>
          <el-button
            @click="handleRemove"
            size="mini"
            type="primary"
            style="min-width: 190px; margin-top: 20px"
            >清除全部</el-button
          >
        </div>
      </div>
      <div class="right">
        <div v-if="searchGoodsData.list.length != 0">
          <div class="top">
            <div class="lefts">
              <span>筛选</span>
              <div
                class="l-btn"
                :class="sxIndex == i ? 'l-btn-active' : ''"
                v-for="(item, i) in sxData"
                :key="i"
                @click="qiehuan(i)"
              >
                {{ item.name }}
              </div>
              <div class="l-btna">
                <span
                  :style="{
                    color: jgIndex == 0 || jgIndex == 1 ? '#ee4d2d' : '',
                  }"
                >
                  {{ jgname }}</span
                >

                <i class="el-icon-arrow-down"></i>
                <div class="jiageList">
                  <div
                    v-for="(item, i) in jgData"
                    :key="i"
                    @click="jiage(item, i)"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                    class="zxc"
                  >
                    价格：{{ item.label }}
                    <i
                      v-if="jgIndex == i"
                      class="el-icon-check"
                      style="color: #ee4d2d"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="r-feny">
              <div style="width: 100px">
                <span style="color: #ee4d2d"> {{ page }}</span
                >/{{ Math.ceil(searchGoodsData.total / 70) }}
              </div>
              <el-pagination
                background
                :current-page="page"
                :page-size="pageSize"
                layout="prev, next"
                :total="searchGoodsData.total"
                @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
          <div class="search-list">
            <SearchGoods
              :data="item"
              v-for="item in searchGoodsData.list"
              :key="item.id"
            ></SearchGoods>
          </div>
          <div
            style="display: flex; justify-content: center; margin-top: 40px"
            class="cvb"
          >
            <el-pagination
              :current-page="page"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="searchGoodsData.total"
              @current-change="pageChange"
            >
            </el-pagination>
          </div>
        </div>
        <div
          v-else
          style="
            font-siez: 18px;
            text-align: center;
            color: rgba(0, 0, 0, 0.54);
            margin-top: 140px;
          "
        >
          <img style="width: 134px; height: 134px" :src="quesheng" alt="" />
          <div>哎呀！我们找不到任何商品。试试关闭一些筛选？</div>
          <div style="margin: 10px 0">or</div>
          <div>
            <el-button type="primary" @click="handleRemove">重设筛选</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchGoods from "@/components/category/SearchGoods";
import { searchGoods } from "@/api/home.js";
export default {
  components: {
    SearchGoods,
  },
  data() {
    return {
      quesheng: require("@/assets/img/home/quexing.png"),
      categoryList: [],
      categoryLists: [],
      show: true,
      index: 999,
      rate: [{ num: 5 }, { num: 4 }, { num: 3 }, { num: 2 }, { num: 1 }],
      checkList: [],
      minNum: "",
      maxNum: "",
      sxData: [{ name: "热门" }, { name: "最新" }, { name: "最热销" }],
      sxIndex: 0,
      jgname: "价格",
      jgData: [
        { label: "低至高", value: 0 },
        { label: "高至低", value: 1 },
      ],
      jgIndex: 99,
      searchGoodsData: {
        list: [],
      }, //搜索结果
      page: 1, //页数
      pageSize: 70,
      order: "", //价格排序
      category_id: sessionStorage.CATEGORYID,
    };
  },
  created() {
    this.$store.state.homeFootData.forEach((item) => {
      if (item.category_id == sessionStorage.CATEGORYID) {
        let cc = JSON.stringify(item);
        this.categoryList = JSON.parse(cc);
        if (this.categoryList.list) {
          this.categoryList.list.forEach((val) => {
            if (val.list && val.list.length != 0) {
  
              val.list.forEach((value) => {
                this.categoryList.list.push(value);
              });
            }
          });
          let arr = this.categoryList.list.slice(0, 5);
          this.categoryLists = arr;
        }
      }
    });
    if (this.$route.query.index && this.$route.query.index != "") {
      this.index = this.$route.query.index;
      this.categoryLists = this.categoryList.list;
      this.show = false;
      this.category_id =
        this.categoryLists[this.$route.query.index].category_id;
    }
    this.getGoodsList();
  },
  methods: {
    getGoodsList() {
      searchGoods({
        type: this.sxIndex,
        goods_status:
          this.checkList.length != 0 ? this.checkList.join(",") : "",
        page_size: this.pageSize,
        keyword: this.$store.state.searchValue,
        order: this.order,
        category_id: this.category_id,
        price_min: this.minNum,
        price_max: this.maxNum,
        brand_id: "",
        page: this.page,
      }).then((res) => {
        if (res.code == 1) {
          this.searchGoodsData = res.data;
        }
      });
    },
    toALLCategory() {
      this.$router.push("allCategory");
    },
    handleClick(data, index) {
      this.category_id = data.category_id;
      this.index = index;
      this.getGoodsList();
    },
    qiehuan(index) {
      this.sxIndex = index;
      this.getGoodsList();
    },
    jiage(data, index) {
      this.jgname = "价格：" + data.label;
      this.jgIndex = index;
      this.sxIndex = 3;
      this.order = data.value;
      this.getGoodsList();
    },
    handleCheck() {
      this.getGoodsList();
    },
    pageChange(page) {
      this.page = page;
      this.getGoodsList();
    },
    toGoodsInfo(id) {
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
    handleRemove() {
      this.maxNum = "";
      this.minNum = "";
      this.checkList = [];
      this.getGoodsList();
    },
  },
};
</script>
<style lang="less" >
.goodsSearchs {
  background: #f5f5f5;
  padding: 30px 0;
  .left {
    width: 190px;
    padding-bottom: 60px;
    // height: 1440px;
    margin-right: 20px;
    .l-all {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 20px;
      cursor: pointer;
    }
    .l-category {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 20px;
      .item {
        display: flex;
        font-size: 14px;
        padding: 8px 10px;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
      }
      .item-active {
        color: #ee4d2d;
      }
    }
    .l-price {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      .el-input--mini .el-input__inner {
        padding: 6px;
      }
    }
    .l-evaluation {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
      .item {
        padding: 4px 0;
        font-size: 12px;
        .el-rate__icon {
          font-size: 16px;
          margin: 2px;
        }
        .el-icon-star-off {
          font-size: 14px;
          padding: 0 1px;
        }
      }
    }
    .l-condition {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
    }
  }
  .right {
    width: 990px;
    .top {
      background: rgba(0, 0, 0, 0.03);
      display: flex;
      height: 60px;
      width: 100%;
      padding: 0 20px;
      align-items: center;
      .lefts {
        width: 100%;
        display: flex;
        font-size: 14px;
        color: #555;
        height: 100%;
        align-items: center;
        .l-btn {
          width: 90px;
          text-align: center;
          line-height: 34px;
          height: 34px;
          background: #fff;
          margin-left: 20px;
          cursor: pointer;
        }
        .l-btna {
          width: 200px;
          padding: 0 10px;
          box-sizing: border-box;
          line-height: 34px;
          height: 34px;
          background: #fff;
          margin-left: 20px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .jiageList {
            position: absolute;
            bottom: -69px;
            left: 0;
            background: #fff;
            width: 100%;
            padding: 0 10px;
            display: none;
            z-index: 3;
            .zxc:hover {
              color: #ee4d2d;
            }
          }
        }
        .l-btna:hover .jiageList {
          display: block;
        }
        .l-btn-active {
          background: #ee4d2d;
          color: #fff;
        }
      }
      .r-feny {
        display: flex;
        align-items: center;
        text-align: right;
        font-size: 14px;
      }
    }

    .search-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }
    .cvb {
      .el-pager li {
        background: transparent;
      }
      .el-pagination button:disabled {
        background: transparent;
      }
      .el-pagination .btn-next,
      .el-pagination .btn-prev {
        background: transparent;
      }
      .el-pager li.active {
        background: #ee4d2d;
        color: #fff;
      }
    }
  }
}
</style>